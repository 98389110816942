.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 50%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 12%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 1000px) {
  .Modal {
    width: 50%;
    left: calc(50% - 250px);
  }
}
