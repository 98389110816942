// /* ========================================================================
//      Component: timeline
//  ========================================================================== */
// .timeline,
// .timeline-alt {
//   list-style: none;
//   padding: 0 0 20px;
//   position: relative;
//   z-index: 0;
// }
// .timeline:before,
// .timeline-alt:before {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   content: " ";
//   width: 1px;
//   background-color: #d1d2d3;
//   left: 18px;
// }
// .timeline .timeline-end,
// .timeline-alt .timeline-end {
//   float: none;
//   clear: both;
// }
// .timeline .popover.left > .arrow:after,
// .timeline-alt .popover.left > .arrow:after {
//   border-left-color: #fff;
// }
// .timeline .popover.right > .arrow:after,
// .timeline-alt .popover.right > .arrow:after {
//   border-right-color: #fff;
// }
// .timeline .popover.left > .popover-title + .arrow:after,
// .timeline-alt .popover.left > .popover-title + .arrow:after {
//   border-left-color: #fafafa;
// }
// .timeline .popover.right > .popover-title + .arrow:after,
// .timeline-alt .popover.right > .popover-title + .arrow:after {
//   border-right-color: #fafafa;
// }
// .timeline > li,
// .timeline-alt > li {
//   margin-bottom: 20px;
// }
// .timeline > li:before,
// .timeline > li:after,
// .timeline-alt > li:before,
// .timeline-alt > li:after {
//   content: " ";
//   display: table;
// }
// .timeline > li:after,
// .timeline-alt > li:after {
//   clear: both;
// }
// .timeline > li.timeline-separator,
// .timeline-alt > li.timeline-separator {
//   position: relative;
//   float: none;
//   clear: both;
//   margin: 0 auto;
//   padding: 40px 0;
// }
// .timeline > li.timeline-separator:first-child,
// .timeline-alt > li.timeline-separator:first-child {
//   padding-top: 0;
// }
// .timeline > li.timeline-separator:before,
// .timeline-alt > li.timeline-separator:before {
//   content: attr(data-datetime);
//   display: inline-block;
//   width: 120px;
//   text-align: center;
//   padding: 0 20px;
//   line-height: 30px;
//   background-color: #b6c5da;
//   color: #fff;
// }
// .timeline > li > .timeline-panel,
// .timeline-alt > li > .timeline-panel {
//   margin-left: 60px;
// }
// .timeline > li > .timeline-panel .popover,
// .timeline-alt > li > .timeline-panel .popover {
//   position: relative;
//   display: block;
//   margin: 0;
//   width: 100%;
//   max-width: none;
// }
// .timeline > li > .timeline-panel .popover .arrow,
// .timeline-alt > li > .timeline-panel .popover .arrow {
//   top: 18px;
//   display: none;
// }
// .timeline > li > .timeline-panel .popover .popover-content .popover-title,
// .timeline-alt > li > .timeline-panel .popover .popover-content .popover-title {
//   background-color: transparent;
//   border: 0;
//   padding-left: 0;
//   padding-top: 0;
// }
// .timeline > li > .timeline-date,
// .timeline-alt > li > .timeline-date {
//   margin: -20px 0 0;
//   display: block;
//   height: 20px;
//   line-height: 20px;
//   font-size: 13px;
// }
// .timeline > li > .timeline-date > time:after,
// .timeline-alt > li > .timeline-date > time:after {
//   content: attr(datetime);
// }
// .timeline > li > .timeline-badge,
// .timeline-alt > li > .timeline-badge {
//   position: absolute;
//   left: 0;
//   width: 36px;
//   height: 36px;
//   border-radius: 500px;
//   line-height: 36px;
//   font-size: 1em;
//   text-align: center;
//   color: #fff;
//   background-color: #999;
//   z-index: 100;
// }
// .timeline > li > .timeline-badge.primary,
// .timeline-alt > li > .timeline-badge.primary {
//   background-color: #5d9cec;
// }
// .timeline > li > .timeline-badge.success,
// .timeline-alt > li > .timeline-badge.success {
//   background-color: #27c24c;
// }
// .timeline > li > .timeline-badge.warning,
// .timeline-alt > li > .timeline-badge.warning {
//   background-color: #ff902b;
// }
// .timeline > li > .timeline-badge.danger,
// .timeline-alt > li > .timeline-badge.danger {
//   background-color: #f05050;
// }
// .timeline > li > .timeline-badge.info,
// .timeline-alt > li > .timeline-badge.info {
//   background-color: #23b7e5;
// }
// .timeline > li > .timeline-badge.inverse,
// .timeline-alt > li > .timeline-badge.inverse {
//   background-color: #131e26;
// }
// .timeline > li > .timeline-badge.green,
// .timeline-alt > li > .timeline-badge.green {
//   background-color: #37bc9b;
// }
// .timeline > li > .timeline-badge.pink,
// .timeline-alt > li > .timeline-badge.pink {
//   background-color: #f532e5;
// }
// .timeline > li > .timeline-badge.purple,
// .timeline-alt > li > .timeline-badge.purple {
//   background-color: #7266ba;
// }
// .timeline > li > .timeline-badge.dark,
// .timeline-alt > li > .timeline-badge.dark {
//   background-color: #3a3f51;
// }
// .timeline > li > .timeline-badge.yellow,
// .timeline-alt > li > .timeline-badge.yellow {
//   background-color: #fad732;
// }

// .timeline-alt .popover .arrow {
//   display: block !important;
// }

// .timeline-title {
//   margin-top: 0;
//   color: inherit;
// }

// .timeline-body > p,
// .timeline-body > ul {
//   margin-bottom: 0;
// }

// .timeline-body > p + p {
//   margin-top: 5px;
// }

// @media only screen and (min-width: 992px) {
//   .timeline:before {
//     left: 50%;
//     margin-left: 18px;
//   }
//   .timeline > li {
//     float: left;
//     clear: left;
//     width: 45%;
//   }
//   .timeline > li.timeline-inverted {
//     float: right;
//     clear: right;
//     margin-top: 50px;
//   }
//   .timeline > li.timeline-separator {
//     text-align: center;
//   }
//   .timeline > li.timeline-separator:before {
//     margin-left: 36px;
//   }
//   .timeline > li > .timeline-badge {
//     left: 50%;
//     margin-left: 0;
//   }
//   .timeline > li > .timeline-date {
//     float: none;
//     position: absolute;
//     width: 40%;
//     left: 50%;
//     top: 18px;
//     margin-left: 54px;
//     margin-top: -10px;
//   }
//   .timeline > li > .timeline-panel {
//     margin: 0;
//   }
//   .timeline > li > .timeline-panel:before {
//     border-left-width: 15px;
//     border-right-width: 0;
//     top: 26px;
//     right: -15px;
//     left: auto;
//   }
//   .timeline > li > .timeline-panel:after {
//     border-left-width: 14px;
//     border-right-width: 0;
//     top: 27px;
//     right: -14px;
//     left: auto;
//   }
//   .timeline > li > .timeline-panel .popover .arrow {
//     display: block;
//   }
//   .timeline > li.timeline-inverted > .timeline-panel {
//     padding-left: 36px;
//   }
//   .timeline > li.timeline-inverted .timeline-date {
//     left: auto;
//     right: 50%;
//     width: auto;
//     margin-left: 0;
//     margin-right: 36px;
//   }
// }

.vertical-timeline-custom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #000;
}
