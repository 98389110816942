.modal-title {
  font-size: 20px;
}

.btn-link {
  padding-left: 0;
  padding-right: 0;
}

.i-circle {
  border: 5px solid #f1f1f1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 80px;
  width: 80px;
  display: inline-block;
  padding-top: 15px;
  margin-bottom: 7px;
}

.i-circle i {
  font-size: 34px;
  vertical-align: middle;
  color: #666
}

.i-circle.success i {
  color: #60c060
}

.i-circle.primary i {
  color: #4d90fd
}

.i-circle.info i {
  color: #5bc0de
}

.i-circle.warning i {
  color: #fc9700
}

.i-circle.danger i {
  color: #da4932
}