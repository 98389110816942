.label-align {
  text-align: right;
}

.space {
  margin-top: 25px;
}

input[name="logo"], input[name="arquivoRodape"] {
  display: none;
}

label[for="logoFiel"], label[for="arquivoRodape"] {
  cursor: pointer;
  padding: 10px 0px;
  background-color:#267AC9;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 10px;
  border-radius: 5px;
  margin: 15px;
}
label[for="logoFiel"]:hover, label[for="arquivoRodape"]:hover {
  background-color: #1e609e;
  transition: .5s;
}

@media (min-width: 768px){
  .llogo, .rrodape {
  padding-right: 30px;
  padding-left: 14px;
  flex-wrap: initial;
  }
  
}