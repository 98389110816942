.modal-custom {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: top;
  top: 3%;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  .container-custom {
    z-index: 20000;
    margin-top: 3%;
    background-color: #fff;
    color: #000;
    width: 60%;
    max-height: 80%;
    border-radius: 20px;
    padding-left: 1%;
    .close-custom {
      background-color: transparent;
      border: none;
      outline: none;
      width: 32px;
      height: 32px;
      right: calc(-100% + 64px);
      top: 16px;
      display: flex;
      cursor: pointer;
      position: relative;
      align-items: center;
      &:before,
      &:after {
        content: " ";
        position: absolute;
        width: 2.5px;
        height: 24px;
        background-color: #000;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    .header-custom {
      // position: absolute;
      margin-left: 2%;
      margin-right: 2%;
      margin-bottom: 3%;
      top: 1%;
    }
    .content-custom {
      margin-top: 2%;
      overflow-y: auto;
      height: 70%;
    }
    .footer-custom {
      display: flex;
      flex-wrap: wrap;
      align-items: right;
      justify-content: flex-end;
      padding: 0px;
      margin-right: 2rem;
      border-top: 1px solid #dee2e6;
      border-bottom-right-radius: calc(0.3rem - 1px);
      border-bottom-left-radius: calc(0.3rem - 1px);
      .button-custom {
        margin: 5px 2px 2px 2px;
      }
    }
  }
}
